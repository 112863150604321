.portfolio{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(49, 47, 47);
    color: rgb(155, 142, 142);
}

.experiences{
    display: flex;
    margin: 40px;
    box-shadow: var(--shadow);
}
.colorchange{
    color: rgb(190, 186, 186);
}
.official,
.unofficial{
    margin: 30px;
    box-shadow:  20px 20px 20px 20px rgba(2, 2, 3, 0.1);
    padding: 20px 40px;
}

.degree{
    margin-bottom: 25px;
}
.exp{
margin-top: 20px;
}

@media screen and (max-width: 922px){
    .experiences{
        display: block;
        box-shadow: -0;
        margin: 0;
    }
    .portfolio {
        min-height: 120vh;
    }
    
}