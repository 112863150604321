.homepage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #d2b9b2 ;
    background-image: url(../../assets/aesthetic-anime-pixelated-background-bnuuk8wf00lrrcvf.gif);
    background-repeat: no-repeat;
    background-size: cover;
    row-gap: 5px;
    border: 1px;
}
img{
   width: 22vh;
    margin: 1px auto;
    margin-bottom: 0;
    max-width: 100px;
    
}
.education{
    font-size: x-large;
}

.hello{
    font-size :38px;
}
.home__page{
border: 1px black solid;
border-radius: 50%;
}
.home__socials{
display: flex;
column-gap: 1.75rem;
justify-content: center;
font-size: x-large;
margin: 8px;
color: white;
}
.home__social-link{
    color: black;
}
button{
   box-shadow: var(--shadow);
   width: 110px;
   margin: 0 auto;
   padding: 8px;
   backdrop-filter: blur(20px);
}
.contactme{
    background-color: #d2b9b2;
    font-size: larger;
    padding: 6px;
    box-shadow: var(--shadow);
    width: 110px;
    margin: 0 auto;
    color: black;
    padding: 8px;
    backdrop-filter: blur(20px);
}
.infoMore{
    color: wheat;
    margin-bottom: 3px;
    margin-left: 10px;
}
.start{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1044px){
    img{
        width: 15vh;
         margin: 1px auto;
         margin-bottom: 0;
         max-width: 100px;
     }
     .fa-brands{
        color: aliceblue;
     }

     .education{
        color: antiquewhite;
        font-size: large;
     }
    
     .hello{
         font-size :30px;
     }
     .contactme{
        font-size: small;
    }
    .start{
        display: none;
    }
}
