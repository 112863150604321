.resume{
    min-height: 110vh;
    background-color: antiquewhite;
    background-image: url(../../assets/goku-ultra-instinct.1920x1080.gif);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 40px;
    text-align: center;
    background-position-x: center;
    margin: 20px 0px 30px 0px;
}
.response{
    display: none;
}

.projects{
    display: grid;
    grid-gap: 5px;
    column-gap: 20px;
    row-gap: 20px;
    grid: 15rem 15rem/ 15rem 15rem 15rem;
    
}
.project1,
.project2,
.project3,
.project4,
.project5,
.project6{
    background-color: transparent;
    filter:blur(1px);
    -o-filter:blur(4px);
    -ms-filter:blur(4px);
    -moz-filter:blur(1px);
    -webkit-filter:blur(1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    box-shadow: 20px 20px 20px 20px rgba(2, 2, 3, 0.1);
}
.project1:hover,
.project2:hover,
.project3:hover,
.project4:hover,
.project5:hover,
.project6:hover {
    background-color: rgba(189,202,219,255);
    filter:blur(1px);
    -moz-filter:blur(0.5px);
    -webkit-filter:blur(0.5px);
    display: flex;
    border-radius: 10px;
}
.para{
    margin: 20px;
}

@media screen and (max-width: 942px){
    .projects{
        grid: 15rem 15rem 15rem/ 15rem 15rem
    }
    .resume{
        min-height: 120vh;
        background-position-x: center;
    }
    .project1,.project2,.project3,.project4,.project5,.project6{
        background-color:  transparent;
    backdrop-filter: blur(10px);
    filter:blur(0px);
    }
    .response{
        display: block;
    }
}

@media screen and (max-width: 608px){
    .projects{
        grid: 15rem 15rem 15rem 15rem /15rem
    }
    .resume{
        min-height: 210vh;
        background-position-x: center;
    }
}