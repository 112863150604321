.about{
    min-height: 100vh;
    background-image: url(https://media.tenor.com/SMtD3mzDKXEAAAAC/anime-raining.gif);
    background-color: rgba(52,46,85,255); 
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
}
.imgzoro{
    border-radius: 5%;
}
.h1tag{
    font-size: 3.9vh;
    color: white;
}
.about__section{
    margin: 30px;
    /* background-color: #ccae98; */
    background-color:  #78a36b transparent;
    backdrop-filter: blur(20px);
    padding: 60px;
    border-radius: 10%;
    margin-top: 100px;
    font-size: 0.9em;
    box-shadow:  60px 60px 60px 60px rgba(2, 2, 3, 0.1);
    text-align: center;
}

.about__section > h3{
margin-bottom: -10px;
}

.h1tag{
    margin-top: 60px;
    margin-bottom: 10px;
}
.social{
    margin: auto;
    margin-top: 15px;
    padding: 15px;
    background-color:  #b8a097;
    border-radius: 10px;
    backdrop-filter: blur(10px);
}
.social:hover{
    background-color: #883c74;
}
.highlight{
    color: #fdfdfd;
    font-weight: bold;
}
.about__photo{
    display: flex;
    flex-direction: column;
}

.shapes{
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
}



@media screen and (max-width: 1044px){
    
     .about{
        flex-direction: column;
        min-height: 120vh;
     }
     .about__section{
        margin-top: 15px; 
    }
    .about__photo{
        margin: 10px auto;
        display: flex;
        flex-direction: column;
    }
   
}
