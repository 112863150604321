.aside{
    position:fixed;
    left: 0;
    top: 0;
    border-radius: 1x solid black;
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    transition: 1s;
    color: white;
    background-color: black;
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
}

.nav__logo{
    color: white;
    font-size: larger;
}


.nav__list{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav__link{
    font-size: 1.5rem;
    font-family: var(--font-bold);
    color: aliceblue;
    display: flex;
    font-size: x-large;
}

.copyright{
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    font-size: var(--large-font-size);
}
.hoverNav{
    display: none;
}

.btn__nav{
    margin-left: 110px;
}



.nav__toggle{
    position: fixed;
    top: 1.25rem;
    left: 1rem;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: var(--body-color);
    padding: 10px;
    border: 1px solid #e8dfec;
    transition: 1s;
}
.top{
    text-align: center;
    display: flex;
    justify-content: center;
}
.aside1{
    left: -110px;
    position:fixed;
    left: 0;
    top: 0;
    border-radius: 1x solid black;
    padding: 2.5rem;
    width: 100px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    color: #e8dfec;
    transition: 1s;
    background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
}

.nav__link:hover  {

font-size: xx-large;
}


@media screen and (max-width: 1186px){
    .aside{
        left: -110px;
    }
    .nav__toggle-open{
        left: 120px;
    }
    .nav__toggle{
        display: flex;
    }
    .nav__link:hover  {

font-size: x-large;


}
}
