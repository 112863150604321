@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');


:root{

    --first-color: hsl(353, 100%, 65%);
    --title-color: hsl(244, 24%, 26%);
    --text-color: hsl(244, 16%, 43%);
    --body-color: hsl(258, 60%, 98%);
    --container-color: #fff;
    


     --body-font: 'rubik ', sans-serif;
     --h1-font-size: 2.25rem;
     --h2-font-size: 1.5rem;
     --h3-font-size: 1.25rem;
     --normal-font-size: 1rem;
     --small-font-size: 0.875rem;
     --smaller-font-size: 0.813rem;



     --font-medium: 500;
     --font-semibold: 600;
     --font-bold: 700;


     --border-radius: 20px;

     --shadow: 0px 5px 20px 0px rgb(69 67 97 /10%);
}


*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
font-family: var(--body-font);
font-size: var(--normal-font-size);
}
a{
    text-decoration: none;
}
button{
    cursor: pointer;
    border: none;
    outline:none;
}
img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
html, body { overflow: hidden}

@media screen and (max-width: 1186px){
    html, body {margin: 0; height: 100%; overflow:scroll}
}